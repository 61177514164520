import React, {useState} from 'react';
import {Input, InputProps, Select, SelectProps} from 'antd';
import { I18nLabel } from '@shared/i18n';
import styled from "styled-components";


export interface SelectInputProps extends Partial<FieldInputProps<any, any>> {
    submitting?: boolean;
    filter?: boolean;
    hideDropdown?: boolean;
    options: OptionType[];
    optionGroups?: OptionGroupType[];
    className?: string;
    defaultValue?: string;
    onChange?: any
    style?: any;
    mode?: 'multiple'|'tag'|undefined
}

const BaseSelect = styled<SelectProps>(Select)`
  width: 100%;
  height: 50px;
  gap: 12px;
  background: rgba(255, 255, 255, 1);
  border: none;

  .ant-select-selector {
    border-radius: 12px !important;
    border: 1px solid rgba(201, 209, 215, 1);
  }
  
  :focus {
    border: 1px solid;
    border-image-source: linear-gradient(154.83deg, #4360E7 9.59%, #0A2CBC 95.76%);
    box-shadow: none !important;
    outline: 0 !important;
    background-color: #ffffff !important;
  }
`

const SelectInput = ({
     submitting,
     options,
     filter,
     hideDropdown = false,
     defaultValue,
     optionGroups,
     onChange: onInputChange,
     ...rest
 }: SelectInputProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <BaseSelect
            style={{width: '100%'}}
            {...rest}
            onChange={onInputChange}
            options={options as any}
            showSearch={options && options?.length >= 10}
            open={dropdownOpen}
            onDropdownVisibleChange={(open) => setDropdownOpen(open)}
            filterOption={(input, option: any) => option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={rest.value === null || rest.value === '' ? undefined : rest.value}
        >
            {(options || []).map((option, i) => (
                <Select.Option value={option.value} key={option.value + i}>
                    <I18nLabel label={option.label} />
                </Select.Option>
            ))}
        </BaseSelect>
    );
};

export default SelectInput;
