import LabelsEng from './labels/en/lables.en'
import LabelsFra from './labels/fr/lables.fr'

export const Language = <any>{
  ENG: 'en',
  FRA: 'fr',
};

export const LanguageNameMapping = <object>{
  [Language.ENG]: 'English',
  [Language.FRA]: 'Français',
};

export const LanguagesBundle = <any>{
  [Language.ENG]: LabelsEng,
  [Language.FRA]: LabelsFra,
};
