export const fontFamily = "Work Sans, sans-serif";
export const borderRadiusBase = '5px';

export const primaryColor = 'rgba(86, 93, 255, 1)';
export const infoColor = '#0A2CBC';
export const minorColor = '#ADB6CC';
export const linkColor = '#8c8a8a';
export const dangerColor = '#d02c2c'
export const linkHoverDecoration = 'underline';
export const backgroundPrimary = 'linear-gradient(154.83deg, #4360E7 9.59%, #0A2CBC 95.76%)';

export const labelColor = primaryColor;
export const normalColor = '#C0C0C0';

export const fontSizeBase = '16px';
export const fontSizeLg = '18px';
export const fontSizeXlg = '28px';
export const fontSizeSm = '14px';

export const borderColorBase = '#ADB6CC';

export const formItemMarginBottom = '19px';

export const disabledBg = '#F8F9FC';

export const layoutBodyBackground = '#F8F9FC';

export const avatarColor = primaryColor;
export const avatarBg = '#E9ECF3';

export const boxBorderDecoration = '1px solid #e9e9e9';

export const boxShadow =`0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)`;
export const boxShadow2 =`0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23)`;
export const boxShadow3 =`0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.23)`;
export const boxShadow4 =`0px 15px 25px rgba(0, 0, 0, 0.14), 0px 5px 10px rgba(0, 0, 0, 0.12)`;
