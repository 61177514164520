import React, {useEffect, useState} from 'react';
import AuthContainer from "@components/layout/AuthContainer";
import {Col, Form, message, Row} from "antd";
import SocialButton from "@components/ui/Button/SocialButton";
import {googleIcon} from "@infrastructure/assets";
import AuthDivider from "@components/ui/AuthDivider";
import {BaseInput, BaseInputPassword} from "@components/ui/Input";
import {appTheme, useModal, useNavigator} from "@shared/core";
import {BaseButton} from "@components/ui/Button/BaseButton";
import {Link} from "react-router-dom";
import {AppRoute} from "@ui/route";
import AuthController from "@controller/AuthController";
import AppAlert from "@components/ui/AppAlert";

function LoginPage() {
    const {navigate} = useNavigator()
    const {loading, onLogin} = AuthController();
    const [error, setError] = useState<any | undefined>()
    const {openModal} = useModal()
    const {urlQuery} = useNavigator()
    const {email} = urlQuery()

    useEffect(() => {
        email && openModal({
            type: 'RESET_PASSWORD_MODAL',
            modalProps: {
                email: email
            }
        })

        return () => {};
    }, [email]);


    return <AuthContainer static={true} title="Hi there!" description={<>Welcome to the <strong>TurnOn.fm</strong>. Your radio station dashboard.</>}>
        <Form
            name="basic"
            layout="vertical"
            onFinish={async (data: any) => {
                setError(undefined)
                let response = await onLogin(data)
                if (response?.error) {
                    setError(response?.error?.message)
                } else {
                    message.success('Success login')
                    navigate('/')
                }
            }}
        >
            <Row gutter={[0, 6]} justify="space-around">
                {/*<Col md={24} xs={24} >*/}
                {/*    <SocialButton icon={<img src={googleIcon} alt=".."/>}>*/}
                {/*        Continue with Google*/}
                {/*    </SocialButton>*/}
                {/*</Col>*/}
                {/*<Col md={20} sm={24}>*/}
                {/*    <AuthDivider/>*/}
                {/*</Col>*/}
                {error && <Col md={24} xs={24}>
                    <AppAlert message={error}/>
                </Col>}
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Email"
                        name="username"
                        rules={[{ required: true, message: 'Please input your email addres!' }]}
                    >
                        <BaseInput placeholder="Enter email address"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <BaseInputPassword placeholder="Enter password"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24} align="end">
                    <div onClick={() => openModal({
                        type: 'RECOVERY_PASSWORD_MODAL',
                    })} style={{ fontWeight: 'bold', color: `${appTheme.primaryColor}`, margin: '0 0 5% 0' }}>
                        Forgot password?
                    </div>
                </Col>
                <Col md={24} xs={24} >
                    <Form.Item>
                        <BaseButton loading={loading} type="primary" htmlType="submit">
                            Log in
                        </BaseButton>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    Don’t have an account? <Link style={{ fontWeight: 'bold', color: `${appTheme.primaryColor}`}} to={AppRoute.REGISTER}>Sign up</Link>.
                </Col>
            </Row>
        </Form>
    </AuthContainer>
}

export default LoginPage;
