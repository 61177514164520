import {useDispatch, useSelector} from 'react-redux';
import {hideAllModals, hideModal, showModal} from "./store";

const MODAL_ANIMATION_DELAY = 300;

export interface OpenModalProps {
   type: any;
   modalProps?: any;
 }

 export const useModal = () => {
  const dispatch = useDispatch();
  const modals = useSelector((state: any) => state.modalSlice);

  const openModal: (props: OpenModalProps) => void = (
      {type, modalProps}: OpenModalProps) =>
      dispatch(showModal({type, modalProps}));

  const closeModal = (modalType: string) => dispatch(hideModal(modalType));

  const closeAllModals = () => dispatch(hideAllModals());

  return { modals, openModal, closeModal, closeAllModals };
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useModal;