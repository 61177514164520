import React from 'react';
import {Col, Row} from "antd";
import {loginBackground} from "@infrastructure/assets";
import styled from "styled-components";

export const LoginImageContainer = styled.div`
  border: none;
  margin: 2% auto;
  width: 98% !important;
  height: 95% !important;
`;

export const ImageContainer = styled.img`
  width: 100% !important;
  height: 100% !important;
  z-index: 10;
`;

export const AuthTitle = styled.h1`
    font-size: 60px;
    font-weight: 600;
    letter-spacing: -0.01em;
    text-align: center;
`;

export const AuthDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
`;

export const LabelContainer = styled.div`
  font-size: 62px;
  font-weight: 800;
  line-height: 76px;
  letter-spacing: 0;
  text-align: left;
  position: absolute;
  z-index: 0;
  top: 42%;
  left: 35%;
  color: white;
`;

interface AuthContainerProps {
    title: string,
    static?: boolean,
    description: string,
    children: React.ReactNode
}

function AuthContainer(props: AuthContainerProps) {
    return <div style={{ padding: 0, margin: 0, minHeight: '100vh' }}>
        <Row justify="space-between">
            <Col md={12} align="center" style={{ margin: 'auto 0' }}>
                <Row gutter={[12, 12]} justify="center">
                    <Col md={24}>
                        <AuthTitle >{props.title}</AuthTitle>
                        <AuthDescription>{props.description}</AuthDescription>
                    </Col>
                    <Col md={16} sm={24} xs={24}>
                        {props.children}
                    </Col>
                </Row>
            </Col>
            <Col md={12} style={props.static ? { height: '100vh'  } : { minHeight: '100vh'  }}>
                <LoginImageContainer >
                    <ImageContainer src={loginBackground} />
                    <LabelContainer>TurnOn.fm</LabelContainer>
                </LoginImageContainer>
            </Col>
        </Row>
    </div>
}

export default AuthContainer;
