
export enum AppRoute  {
    HOME= '/',
    LOGIN= '/login',
    REGISTER= '/register',
    LOGOUT= '/logout',
    PROFILE= '/profile',
    SETTING= '/setting',
    USERS= '/users',
    SCHEDULE= '/schedule',
    SONG_REQUEST= '/song-requests',
    GREETING= '/greetings',
}