import React from 'react';
import {Card, Col, Row} from "antd";
import PageContainer from "@components/layout/PageContainer";

function SchedulePage(props) {


    return <PageContainer pageTitle="Calendar">
            <div>xxxx</div>
    </PageContainer>
}

export default SchedulePage;