import { ModalMap } from './constants/constants';
import React from 'react';
import {useModal} from "./index";


export default function ModalContainer({modal}: {modal?: any}) {
  const { modals, closeModal, closeAllModals } = useModal();

  let AllModals = {
    ...ModalMap,
    ...modal ?? {}
  }

  return (modals?.modals ||  []).map(({ type, modalProps, visible }: any, index: number) => {
    const ModalComponent = (AllModals as any)[type];
    const hideCurrentModal = () => closeModal(type);

    return (
      <ModalComponent
        key={type + index}
        modalProps={{...modalProps}}
        onClose={hideCurrentModal}
        hideAllModals={closeAllModals}
        visible={visible}
      />
    );
  });
}
