import {callAction, callActionWithId} from "@shared/core";

export const greetingCollection = callAction<any>('/api/greeting/lists', 'get');
export const fetchGreeting = callActionWithId<any>('/api/greeting/{id}', 'get');
export const createGreeting = callAction<any>('/api/greeting/create', 'post');
export const embedGreetingCode = callAction<any>('/api/greeting/generate/widget', 'get');
export const deleteGreeting = callActionWithId<any>('/api/greeting/{id}/delete', 'delete');
export const bannedGreeting = callActionWithId<any>('/api/greeting/{id}/banned', 'patch');
export const unbannedGreeting = callActionWithId<any>('/api/greeting/{id}/unbanned', 'patch');
export const markAsReadGreeting = callActionWithId<any>('/api/greeting/{id}/read', 'patch');
export const markAsUnreadGreeting = callActionWithId<any>('/api/greeting/{id}/unread', 'patch');
