import React from 'react';
import PageContainer from "@components/layout/PageContainer";
import {Col, Row} from "antd";
import UpdateProfileForm from "@pages/ProfilePage/UpdateProfileForm";
import UpdatePasswordForm from "@pages/ProfilePage/UpdatePasswordForm";
import {AppCard} from "@components/ui/Card";

function ProfilePage(props) {
    return <PageContainer pageTitle="Profile setting">
        <Row justify="space-between" gutter={[12, 12]}>
            <Col md={12} xs={24}>
                <AppCard title="General settings">
                    <UpdateProfileForm/>
                </AppCard>
            </Col>
            <Col md={12} xs={24}>
                <AppCard title="Password change">
                    <UpdatePasswordForm/>
                </AppCard>
            </Col>
        </Row>
    </PageContainer>
}

export default ProfilePage;