import React, {useState} from 'react';
import {useTranslate} from "@shared/i18n";
import {useForm} from "antd/lib/form/Form";
import GreetingController from "@controller/GreetingController";
import {Col, Form, message, Row} from "antd";
import AppAlert from "@components/ui/AppAlert";
import {BaseInput, BaseTextarea} from "@components/ui/Input";
import {BaseButton} from "@components/ui/Button/BaseButton";
import WidgetContainer from "@components/layout/WidgetContainer";
import {AppCard} from "@components/ui/Card";
import WidgetController from "@ui/widget/WidgetController";
import {useNavigator} from "@shared/core";

function GreetingWidget() {
    const {trans} = useTranslate()
    const [form] = useForm()
    const [error, setError] = useState()
    const {loading, greetStation} = WidgetController()
    const {params} = useNavigator()

    return <WidgetContainer>
        <AppCard>
            <Form
                name="basic"
                form={form}
                layout="vertical"
                onFinish={async (data: any) => {
                    setError(undefined)

                    let response = await greetStation(params?.id, data)

                    if (response?.error) {
                        setError(response?.error?.message)
                    } else {
                        message.success(trans('text.success.message'))
                        form.resetFields()
                    }
                }}
            >
                <Row gutter={[24, 6]} justify="space-around">
                    {error && <Col md={24} xs={24}>
                        <AppAlert message={error}/>
                    </Col>}
                    <Col xs={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input your name!' }]}
                        >
                            <BaseInput placeholder="Anna"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ message: 'Please input your email!' }]}
                        >
                            <BaseInput placeholder="example@gmail.com"/>
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[{ required: true, message: 'Please input your message!' }]}
                        >
                            <BaseTextarea rows={4} placeholder="Write a message"/>
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24} >
                        <Form.Item>
                            <BaseButton loading={loading} type="primary" htmlType="submit">
                                Greet !
                            </BaseButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </AppCard>
    </WidgetContainer>
}

export default GreetingWidget;