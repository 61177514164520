import React from 'react';
import {I18nLabel} from "@shared/i18n";
import {Col, Form, Row} from "antd";
import {BaseButton} from "@components/ui/Button/BaseButton";
import {appTheme} from "@shared/core";
import AppModal from "@shared/core/src/modals/AppModal";

function SendResetPasswordModal(props) {
    const {email} = props.modalProps

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Check your email"/></span><br/>
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>We’ve sent you a password reset link to</span><br/>
            <span style={{ fontSize: 14, fontWeight: "bold", color: appTheme.primaryColor }}>{email}</span><br/>
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>Please also check your spam folder</span>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Row gutter={[24, 24]} justify="space-around" style={{ marginBottom: '30px', padding: '10px'  }}>
            <Col md={24} xs={24} >
                <BaseButton onClick={() => props.onClose()}  type="primary" htmlType="submit">
                    Close
                </BaseButton>
            </Col>
        </Row>
    </AppModal>
}

export default SendResetPasswordModal;