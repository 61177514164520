import React, {useEffect} from 'react';
import {logout} from "@infrastructure/api";

function LogoutPage() {
    useEffect(() => {
        logout()

        return () => {};
    }, []);

    return (
        <div></div>
    );
}

export default LogoutPage;