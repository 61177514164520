import {useState} from "react";
import * as api from "@infrastructure/api";
import {ProfileData} from "@controller/AuthController";

function ProfileController() {
    const [loading, setLoading] = useState(false)

    const getProfile = async () => {
        setLoading(true)
        let response: { data?: ProfileData  } = await api.fetchProfile();
        setLoading(false)

        return response?.data
    }

    const updateProfile = async (data: any) => {
        setLoading(true)
        let response: any = await api.updateProfile(data)
        setLoading(false)

        return response
    }

    const updatePassword = async (data: any) => {
        setLoading(true)
        let response: any = await api.updateProfilePassword(data)
        setLoading(false)

        return response
    }

    return {
        loading,
        getProfile,
        updateProfile,
        updatePassword
    }
}

export default ProfileController;