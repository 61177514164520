import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface Modal {
    type: string;
    modalProps: any;
    visible: boolean;
}

interface ModalState {
    modals: Modal[];
}

const initialState : ModalState = {
    modals: [],
};

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        showModal: (state, action: PayloadAction<{ type: string; modalProps: any }>) => {
            const { type, modalProps } = action.payload;
            state.modals.push({ type, modalProps, visible: true });
        },
        hideModal: (state, action: PayloadAction<string>) => {
            const modalType = action.payload;

            state.modals = state.modals.filter((e) => e.type !== modalType);
        },
        hideAllModals: (state) => {
            state.modals = [];
        },
    },
});

export const { showModal, hideModal, hideAllModals }: {
    showModal: any,
    hideModal: any,
    hideAllModals: any,
} = modalSlice.actions
