import React from 'react';
import styled from "styled-components";
import {Button, ButtonProps} from "antd";

interface BaseButtonProps extends  ButtonProps {
    height?: string,
    padding?: string,
}

export const BaseButton = styled<BaseButtonProps>(Button)`
  margin: 0;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  color: white;
  width: 100% !important;
  height: ${props => props.height || "50px !important"};
  padding: ${props => props.padding || "0 16px 2px 16px"};
  box-shadow: 2px 2px 15px 0 rgba(153, 153, 169, 0.15);
  background: linear-gradient(154.83deg, #4360E7 9.59%, #0A2CBC 95.76%);
  gap: 8px;
  
  .ant-btn-icon {
    padding: 10px 0 0 0;
  }
`;

export const OutlineButton = styled<ButtonProps>(Button)`
  margin: 0;
  border-radius: 10px;
  border: 1px solid #0A2CBC;
  color: #0A2CBC;
  font-size: 16px;
  width: 100% !important;
  height: 50px !important;
  padding: 0 20px 2px 16px;
  box-shadow: 2px 2px 15px 0 rgba(153, 153, 169, 0.15);
  background: none;
  gap: 8px;
`;