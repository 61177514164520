import React from 'react';
import {Col, Dropdown, Menu, Row} from "antd";
import PageButton from "@components/ui/Button/PageButton";
import CustomIcon from "@shared/core/src/icons";
import {Link} from "react-router-dom";
import {I18nLabel} from "@shared/i18n";
import {AppRoute} from "@ui/route";

interface PageContainerProps {
    pageTitle: string,
    pageDescription?: string
    action?: any
    children: React.ReactNode
}

function PageContainer(props: PageContainerProps) {
    return (
        <Row gutter={[0, 12]} justify="space-between">
            <Col xs={24}>
                <Row gutter={[0, 24]} justify="space-between">
                   <Col sm={8} xs={24}>
                       {props.pageDescription && <h5>{props.pageDescription}</h5>}
                       <h1>{props.pageTitle}</h1>
                   </Col>
                    <Col sm={15} xs={20}>
                        {props.action !== undefined ? props.action :
                            <Row justify="end">
                                <Col sm={2} xs={24} align="center">
                                    <PageButton icon={<CustomIcon type="searchIcon"/>}/>
                                </Col>
                                <Col sm={2} xs={24} align="center">
                                    <PageButton icon={<CustomIcon type="notificationIcon"/>}/>
                                </Col>
                            </Row>}
                    </Col>
                    <Col sm={1} align="end">
                        <Dropdown  trigger={['click']} overlay={
                            <Menu theme="dark" mode="inline"
                                 items={[
                                     { key: 'setting', label: <Link to={AppRoute.PROFILE}><I18nLabel label="Account setting"/></Link>},
                                    { key: 'logout', label: <Link to={AppRoute.LOGOUT}><I18nLabel label="Logout"/></Link>},
                                ]}/>}>
                            <PageButton icon={<CustomIcon type="profileIcon"/>}/>
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                {props.children}
            </Col>
        </Row>
    );
}

export default PageContainer;