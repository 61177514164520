import React from 'react';
import {Link, Outlet} from "react-router-dom";
import {MenuProps} from "antd";
import {AppMenu, LayoutContainer} from "@components/layout/Style";
import {appTheme} from "@shared/core";
import {AppRoute} from "@ui/route";
import CustomIcon from "@shared/core/src/icons";

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
    // { key: 1, label: <Link to={AppRoute.HOME}>Dashboard</Link>, icon: <CustomIcon type="dashboardIcon" />},
    // { key: 2, label: <Link to={AppRoute.SCHEDULE}>Schedule</Link>, icon: <CustomIcon type="scheduleIcon" />},
    { key: 3, label: <Link to={AppRoute.HOME}>Greetings</Link>, icon: <CustomIcon type="greetingIcon" />},
    // { key: 5, label: <Link to={AppRoute.SONG_REQUEST}>Song Requests</Link>, icon: <CustomIcon type="songIcon" />},
    { key: 6, label: <Link to={AppRoute.USERS}>Users</Link>, icon: <CustomIcon type="userIcon" />},
    { key: 7, label: <Link to={AppRoute.SETTING}>Settings</Link>, icon: <CustomIcon type="settingIcon" />},
];

function DashboardLayout() {

    return <div style={{ display: 'flex' }}>
            <div style={{
                background: appTheme.backgroundPrimary,
                borderRadius: '4px',
                minHeight:  '100vh', }}>
                <div style={{ margin: '20px auto',width: '45px', height: '45px', borderRadius: '50%', background: 'white' }}>
                </div>
                <AppMenu
                    defaultSelectedKeys={['1']}
                    mode="inline"
                    theme="dark"
                    items={items}
                />
            </div>
            <LayoutContainer>
                <Outlet/>
            </LayoutContainer>
        </div>

}

export default DashboardLayout;