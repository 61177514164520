import {menuLabels} from "./_menu";
import _pageLabel from "./_pageTitle";
import _textLabels from "./_text";
import tabLabels from "./_tab";
import { _modalLabels } from "./_modals";
import { _actionLabels } from "./_actions";

const LabelsEng = {
    ..._actionLabels,
    ...menuLabels,
    ..._textLabels,
    ..._pageLabel,
    ...tabLabels,
    ..._modalLabels,
}

export default LabelsEng;

