import {useState} from "react";
import * as api from "@infrastructure/api";


function WidgetController() {
    const [loading, setLoading] = useState(false)

    const greetStation = async (slug, data) => {
        setLoading(true)
        let response = await api.greetCollection(slug)(data)

        setLoading(false)
        return response;
    }

    return {
        loading,
        greetStation
    };
}

export default WidgetController;