import styled from "styled-components";
import {Input, InputProps} from "antd";

export const BaseInput = styled<InputProps>(Input)`
  width: 100%;
  height: 50px;
  padding: 0 12px;
  border-radius: 12px;
  gap: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(201, 209, 215, 1);

  :focus {
    border: 1px solid;
    border-image-source: linear-gradient(154.83deg, #4360E7 9.59%, #0A2CBC 95.76%);
    box-shadow: none !important;
    outline: 0 !important;
    background-color: #ffffff !important;
  }
`

export const BaseInputPassword = styled<InputProps>(Input.Password)`
  width: 100%;
  height: 50px;
  padding: 0 12px;
  border-radius: 12px;
  gap: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(201, 209, 215, 1);
  
  :hover && :focus {
    border: none;
  }
`

export const BaseTextarea = styled<InputProps>(Input.TextArea)`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  gap: 12px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(201, 209, 215, 1);
  
  :hover && :focus {
    border: none;
  }
`
