import React, {useState} from 'react';
import AppModal from "@shared/core/src/modals/AppModal";
import {I18nLabel} from "@shared/i18n";
import {Col, Form, Row} from "antd";
import {BaseInput, BaseInputPassword} from "@components/ui/Input";
import {BaseButton} from "@components/ui/Button/BaseButton";
import {appTheme, useModal, useNavigator} from "@shared/core";
import AuthController from "@controller/AuthController";
import AppAlert from "@components/ui/AppAlert";

function ResetPasswordModal(props) {
    const {loading, resetPassword} = AuthController()
    const {urlQuery} = useNavigator()
    const [error, setError] = useState()
    const {openModal} = useModal()
    const {email, token} = urlQuery()

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Reset Password"/></span><br/>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Form
            name="basic"
            layout="vertical"
            initialValues={{
                email: email
            }}
            onFinish={async (value) => {
                setError(undefined)
                let response = await resetPassword({
                    email: email,
                    token: token,
                    ...value
                })

                if (response?.error) {
                    setError(response?.error?.message)
                } else {
                    props.onClose()

                    openModal({
                        type: 'SUCCESS_PASSWORD_MODAL',
                        modalProps: {
                            email: value.email
                        }
                    })
                }
            }}
        >
            <Row gutter={[24, 24]} justify="space-around" style={{ marginBottom: '30px', padding: '10px'  }}>
                {error && <Col md={24} xs={24}>
                    <AppAlert message={error}/>
                </Col>}
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Your email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email !' }]}
                    >
                        <BaseInput placeholder="Your email"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password !' }]}
                    >
                        <BaseInputPassword placeholder="Your password"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Confirm password"
                        name="passwordConfirm"
                        rules={[{ required: true, message: 'Please confirm password !' }]}
                    >
                        <BaseInputPassword placeholder="Your confirm password"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24} >
                    <BaseButton loading={loading} type="primary" htmlType="submit">
                        Reset password
                    </BaseButton>
                </Col>
                <Col md={24} xs={24} >
                    <div style={{ textAlign: "center", color: appTheme.primaryColor, fontSize: 16, fontWeight: 'bold' }}>
                        Remember password?
                    </div>
                </Col>
            </Row>
        </Form>
    </AppModal>
}

export default ResetPasswordModal;