import React from 'react';
import PageContainer from "@components/layout/PageContainer";
import {Col, Row, Tabs} from "antd";
import TabPane from "antd/lib/tabs/TabPane";

function SettingPage(props) {
    return <PageContainer pageTitle="settings">
        <Tabs defaultActiveKey="1">
            <TabPane tab="Setting" key="setting">
                Content of Tab Pane 1
            </TabPane>
            <TabPane tab="Blacklists" key="blacklists">
                Content of Tab Pane 1
            </TabPane>
        </Tabs>
    </PageContainer>
}

export default SettingPage;