import React, {useState} from 'react';
import PageContainer from "@components/layout/PageContainer";
import {Col, Empty, Row, Spin} from "antd";
import {AppCard} from "@components/ui/Card";
import GreetingCard from "@components/ui/Card/GreetingCard";
import GreetingForm from "@pages/GreetingPage/GreetingForm";
import {useCollectionController, useModal} from "@shared/core";
import * as api from "@infrastructure/api"
import {BaseInput} from "@components/ui/Input";
import SelectInput from "@components/ui/Input/SelectInput";
import {I18nLabel} from "@shared/i18n";
import {BaseButton} from "@components/ui/Button/BaseButton";
import CustomIcon from "@shared/core/src/icons";

function GreetingPage() {
    const {openModal} = useModal()
    const [refresh, setRefresh] = useState(false)
    const {collection, loading, onSearch, onFilter} = useCollectionController(
        api.greetingCollection, {}, refresh
    )

    return <PageContainer pageTitle="Greetings"
        action={<Row justify="end" gutter={[12, 0]}>
            <Col md={8} xs={24}>
                <BaseButton padding="0 16px 10px 16px"
                    onClick={() => openModal({
                        type: 'EMBED_GREETING_MODAL'
                    })}
                    icon={<CustomIcon type="embedCode"/>} height="40px">
                    Get embed code
                </BaseButton>
            </Col>
        </Row>}>
        <Row gutter={[12, 0]} justify="center">
            <Col md={12} xs={24}>
                <AppCard height="85vh">
                    <Spin spinning={loading}>
                        <Row gutter={[12, 12]}>
                            <Col md={12} xs={24}>
                                <SelectInput
                                    allowClear
                                    onChange={(value) => onFilter(value)}
                                    placeholder="Filter"
                                    style={{width: '100%'}}
                                    options={collection.meta?.filter?.map((item) => {
                                        return {
                                            value: item,
                                            label: <I18nLabel label={`text.${item}`}/>
                                        }
                                    } )}/>
                            </Col>
                            <Col md={12} xs={24}>
                                <BaseInput placeholder="Search..." onChange={(e) => onSearch(e.target.value)}/>
                            </Col>
                            <Col md={24}>
                                {collection.data?.map((item, index) =>
                                    <GreetingCard greeting={item} key={index} setRefresh={setRefresh}/>
                                )}

                                {collection.data?.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </Col>
                        </Row>
                    </Spin>
                </AppCard>
            </Col>
            {/*<Col md={12} xs={24}>*/}
            {/*    <AppCard title="Messages">*/}
            {/*        <GreetingForm setRefresh={setRefresh}/>*/}
            {/*    </AppCard>*/}
            {/*</Col>*/}
        </Row>
    </PageContainer>
}

export default GreetingPage;