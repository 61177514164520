import {useState} from "react";
import * as api from "@infrastructure/api"

export type LoginType = {
    username: string,
    password: string,
}

export type RegisterType = {
    email: string;
    stationName: string;
    firstName: string;
    lastName?: string;
    password: string;
    passwordConfirm: string;
    countryCode?: string;
    timeFormat?: string;
    timeZone?: string;
    locale?: string;
}

export interface ProfileData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}


function AuthController() {
    const [loading, setLoading] =  useState(false)

    const onRegister = async (data: RegisterType): Promise<any> => {
        setLoading(true)
        let response: any = await api.register(data)
        setLoading(false)

        return response
    }

    const onLogin = async (data: LoginType): Promise<any>  => {
        setLoading(true)
        let credentials: any = await api.login(data)
        credentials && api.authSuccess(credentials)
        setLoading(false)

        return credentials
    }

    const requestPassword = async (email: any) => {
        setLoading(true)
        let response: any = await api.requestPassword(email)
        setLoading(false)

        return response
    }

    const resetPassword = async (data: any) => {
        setLoading(true)
        let response: any = await api.restPassword(data)
        setLoading(false)

        return response
    }

    return {
        loading,
        onLogin,
        onRegister,
        requestPassword,
        resetPassword
    };
}

export default AuthController;