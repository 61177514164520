import {useLocation, useNavigate, useParams} from "react-router-dom";

export function useNavigator() {
    const reactRouter = useNavigate();
    const location = useLocation();
    const params = useParams();

    const navigate = (route, params = {}) => {
        const path = Object.keys(params).reduce((acc, key) => {
            return acc.replace(`:${key}`, params[key]);
        }, route);

        reactRouter(path);
    };

    const hashNavigate = (hash?: string) => {
        reactRouter(`${location.pathname}#${hash}`)
    }

    const queryNavigate = (queries?: any) => {
        const query = `?${Object.entries(queries)
            .filter((e) => e[1] !== undefined)
            .map((e) => e.join('='))
            .join('&')}`
        let hash = window.location.hash

        reactRouter(hash ? `${location.pathname}${query}${hash}` : `${location.pathname}${query}`)
    }

    const goBack = () => {
        const {backUrl} = urlQuery()

        if (backUrl) {
            reactRouter(`${backUrl}`)
            return;
        }

        reactRouter(-1)
    };

    const isActive = (route) => {
        return location.pathname === route;
    };

    const urlQuery = () => {
        const searchParams = new URLSearchParams(location.search);
        const params = {};
        for (let [key, value] of searchParams.entries()) {
            params[key] = value;
        }
        return params;
    };

    return {
        hash: window.location.hash,
        params: params,
        path: window.location.pathname,
        navigate,
        hashNavigate,
        queryNavigate,
        goBack,
        isActive,
        urlQuery,
    };
}