import React, {useState} from 'react';
import {Card, Col, message, Row, Spin, Tooltip} from "antd";
import styled from "styled-components";
import CustomIcon from "@shared/core/src/icons";
import {I18nDate, useTranslate} from "@shared/i18n";
import GreetingController from "@controller/GreetingController";
import {useModal} from "@shared/core";

export const GreetingContainer = styled(Card)`
  width: 100%;
  border-radius: 8px;
  padding: 10px 0;
  margin: 10px 0;
  background-color: rgba(239, 244, 250, 1);
  min-height: 100px;
  
  .ant-card-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0 10px;
    min-height: 10px; /* Adjust the height as needed */
    color: rgba(123, 133, 165, 1);
    font-size: 14px;
    font-weight: 500;
    background-color: transparent;
    border-radius: 5px 5px 0 0;
    
    .ant-card-head-title {
      display: inline-block;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    
    .ant-card-extra {
      color: rgba(0, 0, 0, 0.88);
      font-weight: normal;
      font-size: 15px;
      
      span {
        cursor: pointer;
      }
    }
  }

  .ant-card-body {
    margin: 0;

    span {
      color: rgba(23, 56, 198, 1);
      font-size: 16px;
      font-weight: 500;
      line-height: 19.57px;
      text-align: left;
    }

    p {
      cursor: pointer;
    }
  }
`;

function GreetingCard({greeting, setRefresh}: { greeting: any, setRefresh: any }) {
    const {trans} = useTranslate()
    const {openModal} = useModal()
    const [state, setState] = useState(greeting)

    const {
        loading,
        deleteGreeting,
        bannedGreeting,
        unbannedGreeting,
        markAsUnreadGreeting,
        markAsReadGreeting
    } =  GreetingController()

    return <Spin spinning={loading}>
        <GreetingContainer title={<I18nDate date={greeting.createdAt} format="Ppp"/>}
           extra={<Row gutter={[20, 0]}>
               <Col>
                   <Tooltip title={state.isBanned ? "Unbanned auditor" : "Banned auditor"}>
                       <span onClick={async () => {
                           let response = state.isBanned ? await unbannedGreeting(greeting.id) : await bannedGreeting(greeting.id)

                           if (!response.error ) {
                               setState((prev: any) => ({...prev, isBanned: !state.isBanned}))
                               message.success(trans('text.success.message'))
                           }
                       }}>
                           <CustomIcon type={state.isBanned ? "unbannedIcon" : "bannedIcon"}/>
                       </span>
                   </Tooltip>
               </Col>
               <Col>
                   <Tooltip title={state.isRead ? "Mark as unread" : "Mark as read"}>
                       <span onClick={async () => {
                           let response = state.isRead ? await markAsUnreadGreeting(state.id) : await markAsReadGreeting(state.id)

                           if (!response.error ) {
                               setState((prev: any) => ({...prev, isRead: !state.isRead}))
                               message.success(trans('text.success.message'))
                           }
                       }}>
                           <CustomIcon type={state.isRead ? "unreadIcon" : "readIcon"}/>
                       </span>
                   </Tooltip>
               </Col>
               <Col>
                   <Tooltip title="Delete greeting">
                       <span onClick={() => {
                           openModal({
                               type: 'CONFIRMATION_MODAL',
                               modalProps: {
                                   description: 'Your going to handle an irreversible action, are you sure to proceed ?',
                                   onConfirm: async () => {
                                       let response = await deleteGreeting(state.id)

                                       if (!response.error ) {
                                           message.success(trans('text.success.message'))
                                           setRefresh(p => !p)
                                       }
                                   }
                               }
                           })
                       }}>
                           <CustomIcon type="deleteIcon"/>
                       </span>
                   </Tooltip>
               </Col>
           </Row>}>
        <span>{greeting.name} {greeting.email ? ' - ' + greeting.email : ''}</span>
            <p onClick={() => openModal({
                type: 'DISPLAY_GREETING_MODAL',
                modalProps: {
                    greetingId: greeting.id,
                    createdAt: greeting.createdAt
                }
            })}>{greeting.message}</p>
        </GreetingContainer>
    </Spin>
}

export default GreetingCard;