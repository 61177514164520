import axios from 'axios';
import {message} from 'antd';
import {getIsOk} from "@shared/core";
import LocalStorage from "@shared/core/src/api/axios/localStorage";
import {authSuccess, logout} from "../auth";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      return prom.reject(error);
    } else {
      return prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,
  function (error) {
    const originalRequest = error.config;

    if (originalRequest?.url?.includes('/refresh-token') && !getIsOk(error.response)) {
      failedQueue = [];
      message.error('Session expired', 1);
      logout()
      return;
    } else if (originalRequest?.url !== '/login' && 401 === error.response.status && !originalRequest._retry) {
      if (isRefreshing) {
        delete axios.defaults.headers.Authorization;

        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axios(originalRequest) as any;
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = LocalStorage.getRefreshToken();
      return new Promise(function (resolve, reject) {
        axios
          .post('/refresh-token', { refreshToken })
          .then(({ data }) => {
            authSuccess(data);
            processQueue(null, data.token);
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            isRefreshing = false;
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            isRefreshing = false;
            reject(err);
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axios;
