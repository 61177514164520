import axios from './axios';
import {callAction} from "@shared/core";
import LocalStorage from "@shared/core/src/api/axios/localStorage";

export const login = (props: any) => callAction<any>('/api/login', 'post', false, true)(props);

export const register = callAction<any>('/api/registration', 'post', true, true);

export const requestPassword = callAction<any>('/api/password-request', 'patch');
export const restPassword = callAction<any>('/api/password-reset', 'patch');
export const updateProfilePassword = callAction<any>('/api/profile/update/password', 'patch');
export const updateProfile = callAction<any>('/api/profile/update', 'patch');
export const fetchProfile = callAction<any>('/api/profile', 'get');

export const authSuccess = (props: any) => {
  LocalStorage.setAuthToken(props.token);
  LocalStorage.setAppContext(props.refreshToken);

  axios.defaults.headers.Authorization = `Bearer ${props.token}`;
};

export const logout = () => {
  delete axios.defaults.headers.Authorization;

  LocalStorage.clearAll()
  window.location.href = '/login'
};
