import React, {useState} from 'react';
import {useTranslate} from "@shared/i18n";
import {useForm} from "antd/lib/form/Form";
import {Col, Form, message, Row} from "antd";
import AppAlert from "@components/ui/AppAlert";
import {BaseInputPassword} from "@components/ui/Input";
import {BaseButton} from "@components/ui/Button/BaseButton";
import ProfileController from "@controller/ProfileController";

function UpdatePasswordForm() {
    const [form] = useForm()
    const {trans} = useTranslate()
    const [error, setError] = useState()
    const {loading, updatePassword} = ProfileController()

    const onUpdatePassword =  async (data: any) => {
        setError(undefined)

        let response = await updatePassword(data)
        if (response?.error) {
            setError(response?.error?.message)
        } else {
            message.success(trans('text.success.message'))
            form.resetFields()
        }
    }

    return <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={onUpdatePassword}
    >
        <Row gutter={[24, 6]} justify="space-around">
            {error && <Col md={24} xs={24}>
                <AppAlert message={error}/>
            </Col>}
            <Col xs={24}>
                <Form.Item
                    label="Current password"
                    name="currentPassword"
                    rules={[{ required: true, message: 'Please input your Current Password!' }]}
                >
                    <BaseInputPassword placeholder="Password"/>
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item
                    label="New password"
                    name="newPassword"
                    rules={[{ required: true, message: 'Please input your New password !' }]}
                >
                    <BaseInputPassword placeholder="Password"/>
                </Form.Item>
            </Col>
            <Col xs={24}>
                <Form.Item
                    label="Confirm new password"
                    name="confirmPassword"
                    rules={[{ required: true, message: 'Please input your Confirm new password!' }]}
                >
                    <BaseInputPassword placeholder="Password"/>
                </Form.Item>
            </Col>
            <Col md={24} xs={24} >
                <Form.Item>
                    <BaseButton loading={loading} type="primary" htmlType="submit">
                        Save changes
                    </BaseButton>
                </Form.Item>
            </Col>
        </Row>
    </Form>
}

export default UpdatePasswordForm;