import RecoveryPasswordModal from "@components/modals/RecoveryPasswordModal";
import ResetPasswordModal from "@components/modals/ResetPasswordModal";
import SendResetPasswordModal from "@components/modals/SendResetPasswordModal";
import PasswordResetSuccessModal from "@components/modals/PasswordResetSuccessModal";
import ConfirmationModal from "@components/modals/ConfirmationModal";
import {greetingModal} from "@components/modals/Greeting";


const appModal = {
    ...greetingModal,
    'CONFIRMATION_MODAL': ConfirmationModal,
    'RECOVERY_PASSWORD_MODAL': RecoveryPasswordModal,
    'RESET_PASSWORD_MODAL': ResetPasswordModal,
    'SEND_PASSWORD_MODAL': SendResetPasswordModal,
    'SUCCESS_PASSWORD_MODAL': PasswordResetSuccessModal,
}

export default appModal;