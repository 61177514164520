import React from 'react';
import PageContainer from "@components/layout/PageContainer";
import {Card, Col, Row} from "antd";

function SongRequestPage(props) {
    return <PageContainer pageTitle="Song Requests">
        <div>xxxx</div>
    </PageContainer>
}

export default SongRequestPage;