import React from 'react';
import PageContainer from "@components/layout/PageContainer";
import TabPane from "antd/lib/tabs/TabPane";
import {Tabs} from "antd";

function UserPage(props) {
    return <PageContainer pageTitle="User Management">
        <Tabs defaultActiveKey="1">
            <TabPane tab="Users programs" key="programs">
                Content of Tab Pane 1
            </TabPane>
            <TabPane tab="Users management" key="management">
                Content of Tab Pane 1
            </TabPane>
        </Tabs>
    </PageContainer>
}

export default UserPage;