import {appTheme} from "@shared/core";


export function getTheme(): any {
    return {
        token: {
            colorPrimary: appTheme.primaryColor,
            colorInfo: appTheme.primaryColor,
            sizeStep: 4,
            borderRadius: appTheme.borderRadiusBase,
            boxShadow: appTheme.boxShadow2,
            boxShadowSecondary: appTheme.boxShadow,
            colorLink: appTheme.primaryColor,
        },
        components: {
            Typography: {
                titleMarginBottom: 0,
                titleMarginTop: 0,
            },
            Menu : {
              padding: 0,
            },
            Collapse: {
                contentPadding: '0 0',
                headerPadding: '0 0',
            },
        },
    };
}