import {Modal as AntModal, Spin} from 'antd';
import React, {ReactNode} from 'react';
import {useAppState} from "../AppState";
import "./styles/styles.less"
import CustomIcon from "../icons";

interface ModalProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
  width?: number;
  open: boolean;
  onClose(): any;
  noClose?: boolean;
  isLoading?: boolean|undefined;
}

export default function AppModal(props: ModalProps & any) {
    const {loading} = useAppState()
  return (
        <AntModal
        title={props.title}
        open={props.open}
        style={{ borderRadius: '20px' }}
        footer={null}
        width={props.number ?? 500}
        closeIcon={<CustomIcon type="closeIcon"/>}
        onCancel={props.onClose}
        className={props.className}
        closable={!props.noClose}
        {...props}
        >
            <Spin spinning={loading ?? props.isLoading}>
                {props.children}
            </Spin>
        </AntModal>
  );
}
