import {useEffect, useState} from 'react';

type TableParams = {
    defaultPage?: number,
    pageSize?: number,
    total?: number,
}

export type CollectionProps =  {
    meta?: {
        pagination: TableParams
    },
    analytics?: any[]
    data: any[],
}

export interface CollectionResponse {
    meta: {
        pagination: {
            offset: number
            limit: number
            total: number
        }
    }
    analytics?: any[]
    data: any[]
}

export const defaultCollection = {
    meta: {
        pagination: {
            defaultPage: 1,
            pageSize: 10,
            total: 0
        }
    },
    data: []
}
export type QueryType = {
    query?: string,
    order?: string,
    filter?: string,
    dateRange?: {fromDate?: string, toDate?: string},
}

function useCollectionController(
    fetchCollection: (props?: any) => Promise<any>,
    customQuery = {},
    refresh?: any
) {
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState<QueryType>({})
    const [collection, setCollection] = useState<CollectionProps>(defaultCollection)

    useEffect(() => {
        fetchData(1, 10);
    }, [refresh, query])

    const fetchData = async (page?: number,   pageSize?: number,) => {
        setLoading(true)
        let params: any = {
            ...customQuery,
            'page[offset]': page ?? 1,
            'page[limit]': pageSize ?? 10,
            'order': query.order,
            'filter': query.filter,
            'q': query.query
        }

        if (query.dateRange) {
            params.fromDate = query.dateRange.fromDate
            params.toDate = query.dateRange.toDate
        }

        const data: CollectionResponse = await fetchCollection(params)

        setLoading(false)
        setCollection({
            meta: {
                pagination: {
                    current: data?.meta?.pagination.offset,
                    pageSize: data?.meta?.pagination.limit,
                    total: data?.meta?.pagination.total
                },
            },
            data: data?.data,
            ...data
        })
    }

    const onSearch = (text?: string) => setQuery(prev => ({...prev, query: text}))
    const onOrderTable = (order: string) => setQuery(prev => ({...prev, order: order}))
    const onFilter = (filter: string) => setQuery(prev => ({...prev, filter: filter}))
    const setPagination = (page: number, pageSize: number) => fetchData(page, pageSize)
    const onFilterRange = (fromDate: string, toDate: string) => setQuery(prev => (
        {...prev, dateRange: {
            fromDate: fromDate,
            toDate: toDate
        }}))

    const onClear = () => setCollection(defaultCollection)

    return {
        loading,
        collection,
        onOrderTable,
        onFilter,
        setCollection,
        setPagination,
        onFilterRange,
        onSearch,
        onClear
    };
}

export default useCollectionController;