import React from 'react';
import {Alert, AlertProps} from "antd";

interface AppAlertType extends AlertProps {

}

function AppAlert(props: AppAlertType) {
    return <Alert type="error" {...props}/>
}

export default AppAlert;