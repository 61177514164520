import React from 'react';

function Error404() {
    return (
        <div>
            Error page
        </div>
    );
}

export default Error404;