import React from 'react';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {ThemeProvider} from "styled-components";
import {ConfigProvider} from "antd";
import {appTheme, ModalContainer} from "@shared/core";
import {getTheme} from "./theme";
import {TranslationProvider} from "@shared/i18n";
import "./app.less";
import App from "./App";
import {createRoot} from "react-dom/client";
import appModal from "@components/modals/contant";
import {Provider as ReduxProvider} from "react-redux";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {modalSlice} from "@shared/core/src/modals/store";
import {appStateSlice} from "@shared/core/src/AppState/store";

const root = createRoot(document.getElementById('root'));

export const store = configureStore({
    reducer: combineReducers({
        modalSlice: modalSlice.reducer,
        appStateSlice: appStateSlice.reducer,
    }),
})

root.render(
    <BrowserRouter>
        <TranslationProvider>
            <ReduxProvider store={store}>
                <ConfigProvider theme={getTheme()}>
                    <ThemeProvider theme={appTheme}>
                        <App/>
                        <ModalContainer modal={appModal}/>
                    </ThemeProvider>
                </ConfigProvider>
            </ReduxProvider>
        </TranslationProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
