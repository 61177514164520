import React from 'react';
import {I18nLabel} from "@shared/i18n";
import {useNavigator} from "@shared/core";
import {Col, Row} from "antd";
import {BaseButton} from "@components/ui/Button/BaseButton";
import AppModal from "@shared/core/src/modals/AppModal";

function PasswordResetSuccessModal(props) {
    const {email} = props.modalProps
    const {navigate} = useNavigator()

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Success"/></span><br/>
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>Your password has been reset</span><br/>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Row gutter={[24, 24]} justify="space-around" style={{ marginBottom: '30px', padding: '10px'  }}>
            <Col md={24} xs={24} >
                <BaseButton onClick={() => {
                    navigate('/login')
                    props.onClose()
                }}  type="primary" htmlType="submit">
                    Login
                </BaseButton>
            </Col>
        </Row>
    </AppModal>
}

export default PasswordResetSuccessModal;