import styled from "styled-components";
import {Layout, Menu} from "antd";
import {Content} from "antd/es/layout/layout";
import {appTheme} from "@shared/core";

export const AppLayout = styled(Layout)`
  padding: 0;
  margin: 0;
  letter-spacing: 0.75px;
`;

export const LayoutContainer = styled(Content)`
  padding: 2%;
  min-height: 75vh;
`;

export const AppMenu = styled(Menu)`
  width: 90px;
  background: none;
  margin: 50% 0;

  .ant-menu-item {
    display: flex;
    flex-direction: column;
    color: white !important;
    padding-left: 0 !important;
    width: 80%;
    height: 100%;
    margin: 10px auto;

    svg {
      width: Hug (40px);
      padding: 10px;
      border-radius: 8px;
      margin: 2px auto;
    }

    span {
      height: 28px;
      font-size: 14px;
      font-weight: normal;
      line-height: 12px;
      display: inline-flex;
      width: auto;
      overflow: visible;
      padding: 3px;
      white-space: break-spaces;
      margin: auto;
      letter-spacing: 0;
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }

  .ant-menu-item-active {
    background: none;

    svg {
      background: rgba(255, 255, 255, 0.2);
    }
  ;
  }

  .ant-menu-item-selected {
    background: none;

    svg {
      background: rgba(255, 255, 255, 0.2);
    }
  ;
  }
`;

