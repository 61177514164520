import React, {useEffect, useState} from 'react';
import {useTranslate} from "@shared/i18n";
import {useForm} from "antd/lib/form/Form";
import {Col, Form, message, Row, Spin} from "antd";
import AppAlert from "@components/ui/AppAlert";
import {BaseInput} from "@components/ui/Input";
import {BaseButton} from "@components/ui/Button/BaseButton";
import ProfileController from "@controller/ProfileController";

function UpdateProfileForm() {
    const {trans} = useTranslate()
    const [form] = useForm()
    const [error, setError] = useState()
    const {loading, getProfile, updateProfile} = ProfileController()

    useEffect(() => {
        getProfile().then((res) => form.setFieldsValue({
            firstName: res?.firstName,
            lastName: res?.lastName,
            email: res?.email,
            role: res?.role,
        }))

        return () => {};
    }, []);


    return <Form
        name="basic"
        form={form}
        layout="vertical"
        onFinish={async (data: any) => {
            setError(undefined)
            let response = await updateProfile(data)

            if (response?.error) {
                setError(response?.error?.message)
            } else {
                message.success(trans('text.success.message'))
            }
        }}
    >
        <Spin spinning={loading}>
            <Row gutter={[24, 6]} justify="space-around">
                {error && <Col md={24} xs={24}>
                    <AppAlert message={error}/>
                </Col>}
                <Col xs={24}>
                    <Form.Item
                        label="First name"
                        name="firstName"
                        rules={[{ required: true, message: 'Please input your First name!' }]}
                    >
                        <BaseInput placeholder="Anna"/>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        label="Last name"
                        name="lastName"
                        rules={[{ required: true, message: 'Please input your Last name!' }]}
                    >
                        <BaseInput placeholder="Michelle"/>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your Email!' }]}
                    >
                        <BaseInput disabled={true} placeholder="example@mail.com"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24} >
                    <Form.Item>
                        <BaseButton loading={loading} type="primary" htmlType="submit">
                            Save changes
                        </BaseButton>
                    </Form.Item>
                </Col>
            </Row>
        </Spin>
    </Form>
}

export default UpdateProfileForm;