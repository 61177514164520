import React, {useEffect, useState} from 'react';
import AppModal from "@shared/core/src/modals/AppModal";
import {I18nLabel} from "@shared/i18n";
import styled from "styled-components";
import * as api from "@infrastructure/api"
import {Spin} from "antd";

const CodeContainer = styled.div`
  
`;

const StyledTextArea = styled.textarea`
  width: 95%;
  background-color: rgba(5, 5, 5, 0.58);
  height: 50px;
  padding: 10px;
  color: white;
  font-family: monospace;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

function EmbedGreetingModal(props) {
    const {} = props.modalProps
    const [code, setCode] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadCode()

        return () => {};
    }, []);


    const loadCode = async () => {
        setLoading(true)
        let response = await api.embedGreetingCode()
        setCode(response)
        setLoading(false)
    }

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Embed greeting code"/></span><br/>
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>Copy this code and embed on your site</span>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Spin spinning={loading}>
            <CodeContainer>
                <StyledTextArea value={code}/>
            </CodeContainer>
        </Spin>
    </AppModal>
}

export default EmbedGreetingModal;