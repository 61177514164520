import React, {useState} from 'react';
import AppModal from "@shared/core/src/modals/AppModal";
import {I18nLabel} from "@shared/i18n";
import {Col, Row} from "antd";
import {BaseButton} from "@components/ui/Button/BaseButton";

function ConfirmationModal(props) {
    const [loading, setLoading] = useState(false)
    const {description, onConfirm}: {description: string, onConfirm: any} = props.modalProps

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Need to confirm your action"/></span><br/>
            <span style={{ fontSize: 16, fontWeight: 'normal' }}>
                {description}
            </span><br/>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Row gutter={[24, 24]} justify="space-around" style={{ marginBottom: '20px'}}>
            <Col md={12} xs={24} >
                <BaseButton danger onClick={() => props.onClose()}  type="primary" htmlType="submit">
                    Close
                </BaseButton>
            </Col>
            <Col md={12} xs={24} >
                <BaseButton loading={loading} onClick={() => {
                    setLoading(true)
                    onConfirm().finally(() => {
                        setLoading(false)
                        props.onClose()
                    })
                }}  type="primary" htmlType="submit">
                    Confirm
                </BaseButton>
            </Col>
        </Row>
    </AppModal>
}

export default ConfirmationModal;