import React from 'react';
import {Button, ButtonProps} from "antd";
import styled from "styled-components";

export const PageButton = styled<ButtonProps>(Button)`
  margin: 0;
  border-radius: 10px;
  border: none;
  width: 40px !important;
  height: 40px !important;
  padding: 8px;
  
  svg {
    width: 25px !important;
    height: 25px !important;
  }
`;


export default PageButton;