import axios, {AxiosResponse, Method} from 'axios';
import {message as antMessage} from 'antd';
import LocalStorage from "../axios/localStorage";

type Resp = any;

const getMethod = (method: Method, withoutAuth?: boolean) => {
    const appToken = LocalStorage.getAuthToken();

    withoutAuth && delete axios.defaults.headers.Authorization

    if (!withoutAuth && appToken) {
        axios.defaults.headers.Authorization = `Bearer ${appToken}`;
    }

    return ((
        {
            get: axios.get,
            delete: axios.delete,
            head: axios.head,
            post: axios.post,
            put: axios.put,
            patch: axios.patch,
        } as any
    )[method]);
}


export const getIsOk = (res: AxiosResponse<any>) => [200, 202, 201, 204, 203, 403].includes(res.status);

const getErrorObj = (data: any) => {
  return data.errors || { errors: [] };
};

export const getPath = (path: string, method: Method, props: any) => {
  if (['delete', 'get'].includes(method)) {
    return `${path}${
      props
        ? `?${Object.entries(props)
            .filter((e) => e[1] !== undefined)
            .map((e) => e.join('='))
            .join('&')}`
        : ''
    }`;
  }

  return path;
};

export const callAction = <T>(path: string, method: Method, isForm?: boolean, withoutAuth?: boolean): ((props?: T, ops?: any) => Promise<Resp>) => {

  return (props?: T, ops?: any) => getMethod(method, withoutAuth)(getPath(path, method, props), props, ops)
          .then((res: AxiosResponse<any>) => {
              if (isForm) {
                  if (!getIsOk(res) && res.data?.error === undefined) {
                      return { errors: getErrorObj(res.data) };
                  }

                  return res.data;
              } else if (!getIsOk(res)) {
                  return res.error;
              }

              return res.data;
          })
          .catch(({ response }: any) => {
              if (isForm) {
                  return { errors: getErrorObj(response?.data as any) };
              }
              return response?.data as any;
          });
};

export const callActionWithId = <T>(
  path: string,
  method: Method,
  isForm?: boolean,
  options?: {
    customUrlFunc?: (id: any, props: any) => string;
  }
): ((id: any, props?: T) => Promise<Resp | null>) => {
  return (id: any, props?: T) =>
    getMethod(method)(options?.customUrlFunc?.(id, props) || path.replace(/{.+}/, id), props)
      .then((res: AxiosResponse<any>) => {
        if (isForm) {
          if (!getIsOk(res) && res.data?.error === undefined) {
            antMessage.error('Failed to perform action');
            return { errors: getErrorObj(res.data) };
          }
          return res.data;
        } else if (!getIsOk(res)) {
          antMessage.error('Failed to perform action');
          return res.error;
        }

        return res.data;
      })
      .catch(({ response }: any) => {
        if (isForm) {
          return { errors: getErrorObj(response?.data as any) };
        }
        return null as any;
      });
};
