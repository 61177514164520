import React from 'react';
import styled from "styled-components";
import {Card} from "antd";


export const AppCard = styled(Card)<{ height?: string }>`
  width: 100%;
  border-radius: 16px;
  height: ${props => props.height || "auto"};
  
  .ant-card-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: 56px;
    margin: 10px 0;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 22px;
    background: transparent;
    border: none;
    border-radius: 5px 5px 0 0;
    
    .ant-card-head-title {
      display: inline-block;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .ant-card-body {
    margin: 10px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 95%;
    
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }
`;