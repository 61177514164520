import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState : any = {
    loading: false
}

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        appLoading: (state, action: PayloadAction) => {
            state.loading = action.payload;
        },
    },
});

export const { appLoading }: {appLoading: any} = appStateSlice.actions