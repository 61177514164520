import React, {useEffect, useState} from 'react';
import AppModal from "@shared/core/src/modals/AppModal";
import {I18nDate, I18nLabel} from "@shared/i18n";
import * as api from "@infrastructure/api";
import {Spin} from "antd";
import styled from "styled-components";

const GreetingModal = styled(AppModal)`
  padding: 10px 0;
  margin: 10px 0;
  
  .ant-modal-header {
    span {
      color: rgba(123, 133, 165, 1);
    }
  }
  
  .ant-modal-content, .ant-modal-header {
    background-color: rgba(239, 244, 250, 1);
  }
  
  .ant-modal-body {
    span {
      color: rgba(23, 56, 198, 1);
      font-size: 16px;
      font-weight: 500;
      line-height: 19.57px;
      text-align: left;
    }
  }
`;


function DisplayGreetingModal(props) {
    const {greetingId, createdAt} = props.modalProps
    const [greeting, setGreeting] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getGreetingDetails()

        return () => {};
    }, []);


    const getGreetingDetails = async () => {
        setLoading(true)
        let response = await api.fetchGreeting(greetingId)
        response?.data && setGreeting(response.data)
        setLoading(false)
    }

    return <GreetingModal
        title={<div style={{ textAlign: 'left', margin: '0px 0'}}>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
                <I18nDate date={createdAt} format="Ppp"/>
            </span>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Spin spinning={loading}>
            <span>{greeting?.name}  {greeting?.email ? ' - ' + greeting?.email : ''}</span>
            <p>{greeting?.message}</p>
        </Spin>
    </GreetingModal>
}

export default DisplayGreetingModal;