import * as api from "@infrastructure/api";
import {useState} from "react";

function GreetingController() {
    const [loading, setLoading] = useState(false)

    const createGreeting = async (data) => {
        setLoading(true)
        let response = await api.createGreeting(data)
        setLoading(false)
        return response;
    }

    const bannedGreeting = async (greetingId: string) => {
        setLoading(true)
        let response = await api.bannedGreeting(greetingId)
        setLoading(false)
        return response;
    }

    const unbannedGreeting = async (greetingId: string) => {
        setLoading(true)
        let response = await api.unbannedGreeting(greetingId)
        setLoading(false)
        return response;
    }

    const markAsReadGreeting = async (greetingId: string) => {
        setLoading(true)
        let response = await api.markAsReadGreeting(greetingId)
        setLoading(false)
        return response;
    }

    const markAsUnreadGreeting = async (greetingId: string) => {
        setLoading(true)
        let response = await api.markAsUnreadGreeting(greetingId)
        setLoading(false)
        return response;
    }

    const deleteGreeting = async (greetingId: string) => {
        return await api.deleteGreeting(greetingId)
    }

    return {
        loading,
        createGreeting,
        deleteGreeting,
        bannedGreeting,
        unbannedGreeting,
        markAsReadGreeting,
        markAsUnreadGreeting
    }
}

export default GreetingController;