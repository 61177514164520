import {Language, LanguagesBundle} from "./languages";
import {I18nextProvider, useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {format} from "date-fns";
import {enGB, fr} from "date-fns/locale";

const locales = { enGB, fr };

export function useTranslate() {
    const { t, i18n } = useTranslation();

    const trans = (label: any, vars?: any): any => {
      return t(label,  vars)
    }

    const changeLanguage = (lang: string) => i18n.changeLanguage(lang);

    return {
        trans,
        changeLanguage
    }
}

export function I18nLabel({label, vars}: {label: any, vars?: any}) {
    const { t } = useTranslation();

    return t(label,  vars)
}

export function I18nDate(props: {date?: any, vars?: any, format?: string}) {
    if (props.date) {
        return format(new Date(props.date), props.format ?? 'dd/MM/yyyy', {
            locale: enGB
        })
    }

    return ''
}

export const TranslationProvider = ({ children }) => {
    i18n.init({
        resources:  {
            [Language.ENG]: { translation: LanguagesBundle[Language.ENG] },
            [Language.FRA]: { translation: LanguagesBundle[Language.FRA] },
        },
        lng: 'fr', // Default language
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false // React already protects from XSS
        }
    } as any);

    return <I18nextProvider i18n={i18n}>
        {children}
    </I18nextProvider>
};
