import React from 'react';
import AppModal from "../../AppModal";
import {Col, Row} from "antd";
import {I18nLabel} from "@shared/i18n";

function ValidateActionModal(props: any) {
    const {text, children} = props.modalProps

    return (
        <AppModal
            title={<I18nLabel label={"text.action.confirm"}/>}
            open={props.visible}
            onClose={props.onClose}
            noClose={false}
            width={'45%'}
        >
            <Row justify="space-between" gutter={[24, 24]}>
                <Col md={24}>
                    <p style={{ fontSize: '18px' }}>
                        {<I18nLabel label={text}/> ?? <I18nLabel label="text.action.confirm.text"/>}
                        <div>
                            {children}
                        </div>
                    </p>
                </Col>
            </Row>
        </AppModal>
    );
}

export default ValidateActionModal;