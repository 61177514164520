import _text from "./_text";
import _pageLabels from "./_pageTitle";
import _textLabels from "./_text";
import menuLabel from "./_menu";
import tabLabels from "./_tab";
import {_actionLabels} from "./_actions";
import {_modalLabels} from "./_modals";


const labelsFra = {
    'hello.word': 'Bonjour le monde',
    'menu.home': 'Accueil',
    ..._pageLabels,
    ..._textLabels,
    ...menuLabel,
    ...tabLabels,
    ..._actionLabels,
    ..._modalLabels
}

export default labelsFra;
