import React, {useState} from 'react';
import {I18nLabel} from "@shared/i18n";
import AppModal from "@shared/core/src/modals/AppModal";
import {Col, Form, Row} from "antd";
import {BaseInput} from "@components/ui/Input";
import {appTheme, useModal} from "@shared/core";
import {BaseButton} from "@components/ui/Button/BaseButton";
import AuthController from "@controller/AuthController";
import AppAlert from "@components/ui/AppAlert";

function RecoveryPasswordModal(props) {
    const [error, setError] = useState()
    const {openModal} = useModal()
    const {loading, requestPassword} = AuthController()

    return <AppModal
        title={<div style={{ textAlign: 'center', margin: '20px 0'}}>
            <span style={{ fontSize: 18, fontWeight: 600 }}><I18nLabel label="Forgot password? No worries!"/></span><br/>
            <span style={{ fontSize: 14, fontWeight: 'normal' }}>We will send you an email with instruction how to reset your password.</span>
        </div>}
        centered
        open={props.visible}
        onClose={props.onClose}
        noClose={false}
        width={'30%'}
    >
        <Form
            name="basic"
            layout="vertical"
            onFinish={async (value) => {
                setError(undefined)
                let response = await requestPassword(value)

                if (response?.error) {
                    setError(response?.error?.message)
                } else {
                    props.onClose()

                    openModal({
                        type: 'SEND_PASSWORD_MODAL',
                        modalProps: {
                            email: value.email
                        }
                    })
                }
            }}
        >
            <Row gutter={[24, 24]} justify="space-around" style={{ marginBottom: '30px', padding: '10px'  }}>
                {error && <Col md={24} xs={24}>
                    <AppAlert message={error}/>
                </Col>}
                <Col md={24} xs={24}>
                    <Form.Item
                        label="Your email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email !' }]}
                    >
                        <BaseInput placeholder="Your email"/>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24} >
                    <BaseButton loading={loading} type="primary" htmlType="submit">
                        Reset password
                    </BaseButton>
                </Col>
                <Col md={24} xs={24} >
                    <div onClick={() => props.onClose()}
                         style={{ textAlign: "center", color: appTheme.primaryColor, fontSize: 16, fontWeight: 'bold' }}>
                        Or return back to login screen
                    </div>
                </Col>
            </Row>
        </Form>
    </AppModal>
}

export default RecoveryPasswordModal;
