import React from 'react';
import styled from "styled-components";

interface WidgetContainerProps {
    children: React.ReactNode
}

export const WidgetRow = styled.div`
  width: 40%;
  position: absolute;
  top: 15%;
  left: 30%;

  @media (max-width: 768px) {
    width: 90%;
    position: absolute;
    top: 10%;
    left: 5%;
  }
`;

function WidgetContainer(props: WidgetContainerProps) {
    return (
        <WidgetRow >
            {props.children}
        </WidgetRow>
    );
}

export default WidgetContainer;