import {useDispatch, useSelector} from "react-redux";
import {appLoading} from "./store";

export function useAppState() {
    const dispatch = useDispatch();
    const {loading} = useSelector((state: any) => state.appStateSlice);

    const defineLoading = (loading: boolean) => dispatch(appLoading(loading))

    return {
        loading,
        defineLoading
    };
}