import React from "react"
import Error404 from "@pages/errorPage/Error404";
import {Navigate, useRoutes} from "react-router-dom";
import LoginPage from "@pages/LoginPage";
import RegisterPage from "@pages/RegisterPage";
import ProfilePage from "@pages/ProfilePage";
import DashboardLayout from "@components/layout/DashboardLayout";
import {AppRoute} from "@ui/route";
import SettingPage from "@pages/SettingPage";
import UserPage from "@pages/UserPage";
import GreetingPage from "@pages/GreetingPage";
import SchedulePage from "@pages/SchedulePage";
import SongRequestPage from "@pages/SongRequestPage";
import LogoutPage from "@pages/LogoutPage";
import {widgetRoute} from "@ui/widget/WidgetRoute";

function App() {
    return <>
        {useRoutes([
            {
                path: AppRoute.HOME,
                element: <DashboardLayout/>,
                children: [
                    // {path: AppRoute.HOME, element: <DashboardPage/>},
                    {path: AppRoute.HOME, element: <GreetingPage/>},
                    {path: AppRoute.PROFILE, element: <ProfilePage/>},
                    {path: AppRoute.SETTING, element: <SettingPage/>},
                    {path: AppRoute.USERS, element: <UserPage/>},
                    // {path: AppRoute.GREETING, element: <GreetingPage/>},
                    {path: AppRoute.SCHEDULE, element: <SchedulePage/>},
                    {path: AppRoute.SONG_REQUEST, element: <SongRequestPage/>},
                ]
            },
            {path: AppRoute.LOGIN, element: <LoginPage/>},
            {path: AppRoute.LOGOUT, element: <LogoutPage/>},
            {path: AppRoute.REGISTER, element: <RegisterPage/>},
            {path: '*', element: <Navigate to="/404"/>},
            {path: '/404', element: <Error404/>},
            {path: '/', element: <Navigate to="/"/>},
            ...widgetRoute
        ])}
    </>;
}

export default App;
